<template>
  <div class="flex-container">
    <img src="../../public/Cirlce-Logo-Words-transparent.png" class="logo-tank"><img>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
}
</script>

<style scoped>

img {
  padding: 0;
}

.band-title {
  justify-self: center;

  width: 200px;
  height: auto;
  margin: auto;


  z-index: 21;
  position: relative;
}

.flex-container {
  display: flex;
  flex-direction: column;
}
.logo-tank {
  width: 40%;
  height: auto;
  margin: auto;
  padding: 0;
}


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
