<template>
    <div class="featured">
        <a target="_blank" href="https://event.etix.com/ticket/online/performanceSale.do?performance_id=3211087&method=restoreToken">
            <!-- <h2>HELIOGRAPHY</h2>
            <h3>album release @</h3>
            <div class="flex-container">
                <img src="../../public/headliners-logo.png" class="host-logo">
            </div>
            <span class="flex-container-2">
                    <h3 class="small-text">5/26 @ 8pm</h3>
                    <button>Get Tickets</button>
                </span> -->
            <div>
                <img class="featured-flier" src="../../public/ISO_RecordRelease_socials_fb.jpg">
            </div>
        </a>
    </div>
    

</template>

<script>

export default {
    name: 'FeaturedContent'
}

</script>

<style scoped>

button {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    font-weight: bolder;
    background-color: #FFFEFC;
    border: none;

    border: 2px solid rgba(123, 28, 53);

    height: 20px;

}

.featured {
    font-family: 'Montserrat', sans-serif;
    /* margin: auto;
    border: 2px solid #FFFEFC;
    padding-bottom: 10px; */
}

.featured h2 {
    font-size: 40px;
    font-weight: bolder;
    text-shadow: 1px 1px black;
    padding: 0;
    margin: 10px;
}

.featured h3 {
    margin: 0;
    padding: 2px;
}

.featured-flier {
    width: 100%;
}
.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
}

.flex-container-2 {
    font-size: 10px;
}

.host-logo {
    background-color: rgba(123, 28, 53);
    padding: 6px;
    height: 3em;
}

.host-logo div {
    height: 100%;
}

a {
    text-decoration: none;
    color: black;
}
</style>