<template>
    <div class="banner">
        <h2>Listen to 'Heliography' Now!</h2>
        <div class="music-links">
            <a href="https://www.amazon.com/dp/B0C161WGWC/ref=sr_1_5?crid=3N7Q5TZOBWOEH&keywords=isolation+tank+ensemble+heliography&qid=1682100253&sprefix=isolation+tank+ensemble+heliography%2Caps%2C156&sr=8-5" target="_blank"><h3>Amazon</h3></a>
            <a href="https://music.apple.com/us/album/heliography-single/1680335186" target="_blank"><h3>Apple</h3></a>
            <a href="https://open.spotify.com/track/7mwcLU3G6uOonljuDZRYcm" target="_blank"><h3>Spotify</h3></a>
        </div>

    </div>
</template>

<script>

export default {
    name: 'BannerContent',
}

</script>

<style scoped>

.banner {
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
    color: black;
    height: 100%;
    background-image: linear-gradient(rgb(218, 31, 69),rgba(255, 231, 164, 0.75));
    padding-bottom: 10px;
}

.banner h2 {
    letter-spacing: 1px;
}

.music-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.music-links a {
    text-decoration: none;
    background-color: rgba(218, 31, 68, 0.455);
    padding: 0 10px;
    color: black;
}
</style>