<template>
    <div class="nav-row-flex-parent">
        <div class="flex-row-icons">
            <a href="https://isolationtankensemble1.bandcamp.com/album/live-at-la-la-land" target="_blank">
                <img src="../../public/bandcamp-logo.png" class="nav-icon" alt="bandcamp-logo">
            </a>
            <a href="https://soundcloud.com/isolationtankensemble" target="_blank">
                <img src="../../public/soundcloud-icon.png" class="nav-icon" alt="soundcloud-logo">
            </a>

            <a href="https://isolationtankensemble1.bandcamp.com/merch" target="_blank">
                <svg alt="merch-logo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 nav-icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                </svg>
            </a>

            <a href="mailto:barnettmateo@gmail.com?subject=Isolation Tank Ensemble Inquiry" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 nav-icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    name: "NavigationComponent",
}
</script>

<style scoped>

.nav-row-flex-parent {
    display: flex;
    flex-direction: column;
}

.flex-row-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nav-icon {
    width: 25px;
    height: auto;
    padding: 2px;
}


a {
    padding: 20px;
    text-decoration: none;
    color: black;
}

.adjust-margin {
    margin-top: -10px;
}
.icon-image {
    width: 25px;
    height: auto;
    margin: auto;
}
.nav-container {
    font-family: 'Noto Nastaliq Urdu', serif;
    letter-spacing: 1px;
    font-size: 10px;
    text-transform: lowercase;

    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
}

.nav-icon-card {
    display: flex;
    flex-direction: column;
}

.nav-title {
    padding: 0;
}

</style>