<template>
    <div class="about-content">
        <div>Cinematic Trash Prog Six Piece</div>
        <div class="super-small">from Louisville, Kentucky</div>
    </div>
</template>

<script>
export default {
    name: 'AboutContent',
}

</script>

<style scoped>

.about-content {
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    margin: auto;
    padding: 5px;
    line-height: 30px;
    text-transform: lowercase;
    letter-spacing: .5px;
}

.super-small {
    font-size: 12px;
}

</style>