<template>
  <div id="app-container">
    <div class="banner">
      <BannerContent />
      <div class="icon-div"> 
        <div class="top-page-icons">
          <a href="https://www.leoweekly.com/2021/09/march-of-the-war-mage-is-a-rowdy-battle-song-by-louisville-trash-prog-band-isolation-tank-ensemble/" target="_blank">
            <img src="../public/leo-logo.png" class="logo-leo" alt="leo-louisville-logo"><img>
          </a>
          <a href="https://www.facebook.com/isolationtankensemble/" target="_blank">
            <img src="../public/facebook-logo.png" class="logo" alt="facebook-logo"><img>
          </a>
          <a href="https://www.instagram.com/isolationtankensemble/?hl=en" target="_blank">
            <img src="../public/instagram-logo.png" class="logo" alt="instagram-logo"><img>
          </a>
          <a href="https://www.youtube.com/watch?v=SfVSH7Xj4XE" target="_blank">
            <img src="../public/youtube-logo-2431.png" class="logo" alt="youtube-logo"><img>
          </a>
        </div>
      </div>
    </div>

        
  <div id="opaque-content">
      <NavigationComponent />
      <MainPage />
      <AboutContent /> 
      <FeaturedContent />
    </div> 

    </div>
      <div class="copyright">
      <span>Website designed and owned by Isolation Tank Ensemble&copy; </span>
    </div> 
</template>

<script>
import MainPage from './components/MainPage.vue'
import NavigationComponent from './components/NavigationComponent.vue'
import AboutContent from './components/AboutContent.vue'
import BannerContent from './components/BannerContent.vue'
import FeaturedContent from './components/FeaturedContent.vue'

export default {
  name: 'App',
  components: {
    MainPage,
    NavigationComponent,
    AboutContent,
    BannerContent,
    FeaturedContent
  }
}
</script>

<style>

/* this code gets rid of white space around background-image */
html, body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: block;

  background: url('../public/ISO_Heliography_SimpleLayers_1400.jpg');
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;
}

#opaque-content {
  background:rgba(255, 231, 164, 0.791);
  width: 80%;
  height: 50%;
  margin: auto;
  margin-top: 50px;

  border: 2px solid rgba(255, 253, 250, 0.404);
  overflow: hidden;
  display: block;
  padding: 10px;

}

.app-container {
  display: block;
  /* height: 100%; */
}

.banner {
  overflow: hidden;
}

.copyright {
  font-family: 'Noto Nastaliq Urdu', serif;
  font-size: 10px;
  margin: auto;
  letter-spacing: 1px;
  width: 100%;
}


.icon-div {
  display: flex;
}

.logo {
  height: 25px;
  width: 25px;
  padding: 1px;
}

.logo-leo {
  width: 40px;
  height: auto;
  padding: 1px;
}

.top-page-icons {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: fixed;
}



</style>
